//colors
$color-blue-dark: #002554;
$color_blue_auto: #0e4fcd;
$color_blue_light: #165eeb;
$color_blue_dark_2: #000255;
$color_blue_light_2: #165eeb;
$color_blue: #14b9ff;
$color-blue-medium: #00174f;
// $color-blue-light: #00AEEF;
$color-gray-dark: #878787;
$color-gray-light: #d8d8d8;
$color-green: #28a745;
$color-orange: #ffb800;
$color-brown: #d67900;
$color-black: #424242;
$color-red-light: #ff6060;
$color-red: #f42f43;
$color-white: #ffffff;
$color-orange-dark: #eb8500;

//borders
$border: 1px solid;
$border-radius: 37px;

// footer
$footer-height: 80px;

//sidebar
$sidebar-width: 240px;
