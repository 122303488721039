@import "@/shared/variables";

.box{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  // width: 100%;
  background-color: $color-white;
  border: $border $color-gray-light;
  border-radius: 5px;
  padding: 15px 20px 10px 25px
}

// @media (min-width: 992px) {
//   .box{
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 700px;
//     // width: 100%;
//     background-color: $color-white;
//     border: $border $color-gray-light;
//     border-radius: 5px;
//     padding: 15px 20px 10px 25px
//   }
// }

.icon-container {
  display: flex;
  justify-content: end;
  padding-right: 3px;
}

.question-container {
  margin-bottom: 30px;
}

.question-cancel {
  text-align: center;
  color: $color_blue_dark_2;
  font-size: 20px;
  font-weight: 900;
  margin: 0;

  @media screen and (min-width:768px) {
    font-size: 18px;
  }
}

.container-motivo {
  margin-bottom: 15px;
}

.motivo {
  text-align: left;
  color: $color-blue-dark;
  font-size: 14px;
  font-weight: bold;
}

.input {
  color: $color-blue-dark;
  border: $border $color-gray-dark;
  border-radius: 26px;
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  opacity: 0.7;
  padding: 8px 0px 10px 10px;
}

.container-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

@media (max-width:550px) {

  .box{
    position: absolute;
    top: 1rem;
    left: 0;
    transform: translate(0, 0);
    width: calc(100% - 20px);
    margin: 0 10px;
    background-color: $color-white;
    border: $border $color-gray-light;
    border-radius: 5px;
    padding: 15px 20px 10px 25px
  }
  .container-buttons {
   flex-wrap: wrap !important;
  }

}